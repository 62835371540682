import ACTION_TYPES from "../ACTION_TYPES";

export const activateSection = sectionId => ({
    type: ACTION_TYPES.PAGE_INFO.ACTIVATE_SECTION,
    payload: {sectionId}
});

export const toggleSectionActivation = sectionId => ({
    type: ACTION_TYPES.PAGE_INFO.TOGGLE_SECTION_ACTIVATION,
    payload: {sectionId}
});

export const setBreakpoint = breakpoint => ({
    type: ACTION_TYPES.PAGE_INFO.SET_BREAKPOINT,
    payload: {breakpoint}
});
