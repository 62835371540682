import CONSTANTS from "../CONSTANTS/CONSTANTS";

export const mapViewportDimensionsToBreakpoint = viewportDimensions => {

    const viewportWidth = viewportDimensions?.width;
    let breakpoint = '';
    const {XS, SM, MD, LG, XL} = CONSTANTS.BOOTSTRAP_BREAKPOINTS;
    switch (true) {
        case viewportWidth <= XS.MAX_WIDTH:
            breakpoint = XS;
            break;
        case viewportWidth <= SM.MAX_WIDTH:
            breakpoint = SM;
            break;
        case viewportWidth <= MD.MAX_WIDTH:
            breakpoint = MD;
            break;
        case viewportWidth <= LG.MAX_WIDTH:
            breakpoint = LG;
            break;
        case viewportWidth <= XL.MAX_WIDTH:
            breakpoint = XL;
            break;
        default:
            breakpoint = LG;
            break;
    }
    return breakpoint;
};
