import React from 'react';
import classnames from "classnames";
import {useAddSectionToScrollspy} from "../../CustomHooks/CustomHooks";
import CONSTANTS from "../../../CONSTANTS/CONSTANTS";

const OurBrands = () => {
    useAddSectionToScrollspy(ID);

    return <section id={ID} className={classnames('blv-brand-quicklinks', 'blv-page-section')}>
        <div>
            <div className={'blv-quicklinks-intro'}>
                <h2>Trots op onze<br/>Beliving familie</h2>
                <p>Beliving is vanaf nu het moederbedrijf van Exterioo en Juntoo.
                    Maar naar buiten toe verandert er niets. We blijven onze meubelen communiceren vanuit
                    onze retailmerken. Zij zijn altijd onze trots geweest. En zullen dat ook blijven.</p>
            </div>
            <div className={'blv-quicklinks-wrapper'}>
                <div className={'blv-quicklink'}>
                    <div>
                        <svg className={classnames('blv-exterioo-tuinmeubelen-logo', 'blv-brand-logo')}>
                            <use
                                xlinkHref={'assets/svgs/blv-exterioo-tuinmeubelen-logo.svg#blv-exterioo-tuinmeubelen-logo'}/>
                        </svg>
                        <p>Bij Exterioo willen we je inspireren om nog meer uit je buiten-leven te halen. Dat doen we
                            met het ruimste assortiment tuinmeubelen, wat je buitenruimte, stijl of budget ook is.</p>
                    </div>
                    <a target={'_blank'} rel={'noreferrer'} href={CONSTANTS.LINKS.EXTERIOO}
                       className={classnames('blv-btn', 'blv-exterioo-tuinmeubelen-cta')}>Vind je inspiratie voor
                        buiten <i className="fas fa-arrow-right"></i></a>
                </div>
                <div className={'blv-quicklink'}>
                    <div>
                        <svg className={classnames('blv-juntoo-logo', 'blv-brand-logo')}>
                            <use xlinkHref={'assets/svgs/blv-juntoo-logo.svg#blv-juntoo-logo'}/>
                        </svg>
                        <p>Thuis is de plek waar je jezelf kan zijn. Waar alles mag en kan. Dat is voor iedereen
                            anders. Daarom bieden we een ruim assortiment om je eigen, unieke thuis te creëren.</p>
                    </div>
                    <a target={'_blank'} rel={'noreferrer'} href={CONSTANTS.LINKS.JUNTOO}
                       className={classnames('blv-btn', 'blv-juntoo-cta')}>Vind je droominterieur <i
                        className="fas fa-arrow-right"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
};

export default OurBrands;
export const ID = 'our-brands';
