import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CONSTANTS from "../../CONSTANTS/CONSTANTS";

export const Picture = ({XS, SM, MD, LG, XL, src, alt, extraClassName, figCaption}) => {

    const className = classnames('gc-picture', extraClassName);
    const {XS: XS_BP, SM: SM_BP, MD: MD_BP, LG: LG_BP} = CONSTANTS.BOOTSTRAP_BREAKPOINTS;

    return <figure>
        <picture>
            {XL ? <source media={`(min-width: ${LG_BP.MAX_WIDTH + 1}px)`} srcSet={XL}/> : null}
            {LG ? <source media={`(min-width: ${MD_BP.MAX_WIDTH + 1}px)`} srcSet={LG}/> : null}
            {MD ? <source media={`(min-width: ${SM_BP.MAX_WIDTH + 1}px)`} srcSet={MD}/> : null}
            {SM ? <source media={`(min-width: ${XS_BP.MAX_WIDTH + 1}px)`} srcSet={SM}/> : null}
            {XS ? <source media={`(min-width: 0)`} srcSet={XS}/> : null}

            <img {...{
                className,
                src: XS || SM || MD || LG || XL || src,
                alt: alt || ''
            }}/>
        </picture>
        {figCaption && <figcaption>{figCaption}</figcaption>}
    </figure>;
};

Picture.propTypes = {
    XS: PropTypes.string,
    SM: PropTypes.string,
    MD: PropTypes.string,
    LG: PropTypes.string,
    alt: PropTypes.string,
    src: PropTypes.string,
    extraClassName: PropTypes.string,
    figCaption: PropTypes.string
};

export default Picture;
