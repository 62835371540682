import React from 'react';
import classnames from 'classnames';
import {useAddSectionToScrollspy} from "../../CustomHooks/CustomHooks";
import Picture from "../../partials/Picture";
import reponsiveBannerImages from "./assets/index";

const AboutUs = () => {

    useAddSectionToScrollspy(ID);

    return <section id={ID} className={classnames('blv-about-us-section', 'blv-page-section')}>
        <div className={classnames('row', 'blv-about-us-text')}>
            <div className={classnames('col-12', 'col-sm-4', 'blv-title-text')}>
                <h2>We inspire
                    people to
                    enjoy unique
                    moments at home.</h2>
            </div>
            <div className={classnames('col-12', 'col-sm-8', 'blv-intro-text')}>
                <p>Wij geloven dat thuis de enige plek is waar je echt jezelf kunt zijn. Waar je je kan ontspannen
                    en kan genieten van kostbare tijd. Alleen of met de mensen van wie je het meest houdt. En
                    we willen je helpen om van je huis de plek te maken waar je het liefst bent.</p>
                <p>Daarom bieden we een breed assortiment toegankelijke en duurzame meubelen met een
                    lange levensduur aan -voor binnen en buiten. Meubelen die ook modulair zijn, zodat je ze
                    kan aanpassen aan je persoonlijke stijl, smaak en budget.</p>
                <p>Wij helpen daarbij met omnichannel advies, inspiratie en service. Met als doel iedereen te
                    inspireren om thuis nog veel meer te genieten van unieke momenten.</p>
            </div>
        </div>
        <div className={'blv-intro-images-banner'}>
            <div className={classnames('blv-aesthetic-img-wrapper', 'blv-left-aesthetic-img-wrapper')}>
                <Picture {...{
                    XS: reponsiveBannerImages.aboutUs1Xs,
                    SM: reponsiveBannerImages.aboutUs1Lg
                }}/>
            </div>
            <div className={classnames('blv-aesthetic-img-wrapper', 'blv-right-aesthetic-img-wrapper')}>
                <Picture {...{
                    XS: reponsiveBannerImages.aboutUs2Xs,
                    SM: reponsiveBannerImages.aboutUs2Lg
                }}/>
            </div>
        </div>
    </section>;
};

export default AboutUs;
export const ID = 'about-us';
