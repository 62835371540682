import initialState from "../../initialState";
import ACTION_TYPES from "../../actions/ACTION_TYPES";

const activateSectionReducer = (state, payload) => {

    const newState = {...state};
    const sectionIdToBeActivated = payload?.sectionId;
    const newCurrentSection = newState.currentSection = {};

    newCurrentSection.id = sectionIdToBeActivated;

    return newState;
};
const toggleSectionActivation = (state, payload) => {
    const newState = {...state};
    const newCurrentSection = newState?.currentSection || {};

    newCurrentSection.id = newCurrentSection?.id;

    return newState;
};

const setBreakpoint = (state, payload) => {
    const newState = {...state};
    newState.breakpoint = payload?.breakpoint || newState.breakpoint || {};

    return newState;
};


export default function (state, action) {
    switch (action.type) {
        case ACTION_TYPES.PAGE_INFO.ACTIVATE_SECTION:
            return activateSectionReducer(state, action?.payload);
        case ACTION_TYPES.PAGE_INFO.TOGGLE_SECTION_ACTIVATION:
            return toggleSectionActivation(state, {sectionId: null});
        case ACTION_TYPES.PAGE_INFO.SET_BREAKPOINT:
            return setBreakpoint(state, action?.payload);
        default:
            return state || initialState.loadInitials().pageInfo;
    }
}
