import {combineReducers, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

import pageInfo from './reducers/pageInfo/pageInfo.reducer';

const reducer = combineReducers({
    pageInfo
});

export default initialState => createStore(reducer, initialState, composeWithDevTools());
