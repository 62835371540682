import React, {useEffect, useState} from 'react';
import classnames from "classnames";
import {useSelector} from "react-redux";
import Tabs from "./Tabs/Tabs";
import {ID as HeroId} from '../Hero/Hero';
import Footer from "../Footer/Footer";


const Navigation = () => {
    const breakpoint = useSelector(state => state?.pageInfo?.breakpoint);

    const toggleMenu = () => setSideMenuIsOpened(prev => !prev);
    const [sideMenuIsOpened, setSideMenuIsOpened] = useState(false);
    const currentActiveSectionId = useSelector(state => state?.pageInfo?.currentSection?.id);
    const [isNavigationBarTransparant, setIsNavigationBarTransparant] = useState(true);

    useEffect(() => {
        let bodyNode = document.getElementsByTagName('body')[0];
        if (sideMenuIsOpened) {
            bodyNode.style.overflow = 'hidden';
        } else {
            bodyNode.style.overflow = '';
        }
    }, [sideMenuIsOpened]);
    useEffect(() => {
        setIsNavigationBarTransparant(currentActiveSectionId === HeroId);
    }, [currentActiveSectionId]);

    return <>
        <nav className={classnames('blv-horizontal-menu', {'blv-menu-is-transparant': isNavigationBarTransparant})}>
            <div className={'blv-navigation-tabs-wrapper'}>
                <a href={'#'}>
                    <svg className={classnames('blv-beliving-logo-black', 'blv-beliving-logo')}>
                        <use xlinkHref={'assets/svgs/beliving_logo_black.svg#beliving_logo_black'}/>
                    </svg>
                </a>
                {breakpoint?.isMobile ? <i onClick={toggleMenu} className="fas fa-bars icon"></i> :
                    <Tabs {...{setSideMenuIsOpened, isInSideMenu: false}}/>}
            </div>
        </nav>

        <nav
            className={classnames('blv-mobile-side-navigation', {'blv-side-navigation-is-collapsed': sideMenuIsOpened})}>
            <div className={'blv-sidemenu-top-bar'}>
                <svg className={classnames('blv-beliving-logo-white', 'blv-beliving-logo')}>
                    <use xlinkHref={'assets/svgs/beliving_logo_black.svg#beliving_logo_black'}/>
                </svg>
                <i onClick={toggleMenu} className="fas fa-times icon"></i>
            </div>
            <Tabs {...{setSideMenuIsOpened, isInSideMenu: true}}/>
            <Footer {...{isFooterInSideMenu: true}}/>
        </nav>
    </>;
};

export default Navigation;
