import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Beliving from "../Beliving";

const RoutedApp = () => {
    return <Router>
        <Switch>
            <Route exact path="/"><Beliving/></Route>
            <Redirect from={'/'} to={'/'}/>
        </Switch>
    </Router>
};

export default RoutedApp;
