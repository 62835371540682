import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {activateSection} from "../../store/actions/PageInfo/PageInfo.action";
import {mapViewportDimensionsToBreakpoint} from "../../utils/mapViewportDimensionsToBreakpoint";
import {setBreakpoint} from '../../store/actions/PageInfo/PageInfo.action';

export const useAddEventListener = (eventType, eventHandler) => {
    useEffect(() => {
        window.addEventListener(eventType, eventHandler);
        return () => window.removeEventListener(eventType, eventHandler);
    }, [eventHandler, eventType]);
}

export const useBreakpointInit = () => {
    const [viewportDimensions, setViewportDimensions] = useState({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight
    });
    const dispatch = useDispatch();

    const handleResize = e => {
        const width = document.documentElement.clientWidth;
        const height = document.documentElement.clientHeight;
        setViewportDimensions({width, height});
    }
    useAddEventListener('resize', handleResize);

    useEffect(() => {
        const breakpoint = mapViewportDimensionsToBreakpoint(viewportDimensions);
        dispatch(setBreakpoint(breakpoint));
    }, [viewportDimensions, dispatch]);
}

export const useIntersectionCallback = (sectionId, callback) => {
    useEffect(() => {
        const io = new IntersectionObserver(callback, {threshold: 0.4});
        const node = document.getElementById(sectionId);

        node && io.observe(node);
    }, [callback, sectionId]);
}

export const useAddSectionToScrollspy = ID => {
    const dispatch = useDispatch();

    const intersectionCallback = entries => {
        const relevantEntry = entries[0];
        const isIntersecting = relevantEntry?.isIntersecting;

        if (isIntersecting) {
            dispatch(activateSection(ID));
        }
    };

    useIntersectionCallback(ID, intersectionCallback);
};
