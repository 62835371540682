import React from 'react';
import RoutedApp from "./components/Routing/RoutedApp";
import {Provider as ReduxProvider} from "react-redux";
import createStore from './store/store';

const App = () => {
    const store = createStore();

    return <ReduxProvider store={store}>
        <RoutedApp/>
    </ReduxProvider>
};

export default App;
