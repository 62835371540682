import React from 'react';
import classnames from "classnames";
import {useSelector} from "react-redux";
import CONSTANTS from "../../../CONSTANTS/CONSTANTS";

const Footer = ({isFooterInSideMenu}) => {
    const breakpoint = useSelector(state => state?.pageInfo?.breakpoint);

    const isMobile = breakpoint?.isMobile;
    return <section id={ID} className={classnames('blv-footer-section', {'blv-side-menu-footer': isFooterInSideMenu})}>
        <footer className={'container'}>
            <div className={'row'}>
                <div className={classnames('col-12', 'col-sm-2', {'blv-side-menu-logo-wrapper': isFooterInSideMenu})}>
                    <svg className={'blv-beliving-logo'}>
                        <use xlinkHref={'assets/svgs/beliving_logo_circles.svg#beliving-logo-circles'}/>
                    </svg>
                </div>
                <div className={classnames('col-12', 'col-sm-3')}>
                    <strong>Beliving</strong>
                    <address>
                        <span className="blv-street-address">Foreestelaan 84</span>
                        <br/>
                        <span className="blv-postal-number">9000</span> <span
                        className="blv-postal-number">Gent, </span>
                        <span className="blv-country-name">België</span>
                    </address>
                </div>
                <div className={classnames('col-12', 'col-sm-3')}>
                    <br/>
                    <address>
                        <a href="mailto:info@beliving.be">info@beliving.be</a>
                        <br/>
                        {/*<a href="tel:+3290000000">090 00 00 00</a> TODO: add when number is in use*/}
                    </address>
                </div>
                <div className={classnames('col-12', 'col-sm-4', 'blv-connect-footer-section')}>
                    {!isFooterInSideMenu && <>
                        <strong>Connect with us</strong>
                        {!isMobile && <br/>} <a rel="noopener noreferrer" target={'_blank'} href={CONSTANTS.LINKS.LINKED_IN}><i className="fab fa-linkedin icon"></i></a>
                        {/*
                        TODO: add this when those pages exist
                        <br/>
                        <a href={'#'}><small>Terms & Conditions | </small></a>
                        <a href={'#'}><small>Privacy Policy | </small></a>
                        <a href={'#'}><small>Cookie Policy</small></a>*/}
                    </>}
                </div>
            </div>
        </footer>
        <span className={classnames('blv-full-width-bg', {'side-menu-footer': isFooterInSideMenu})}/>
    </section>
};

export default Footer;
export const ID = 'main-footer';
