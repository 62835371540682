const CONSTANTS = {
    BOOTSTRAP_BREAKPOINTS: {
        //Make sure this mathes with
        XS: {
            MAX_WIDTH: 575,
            NAME: 'XS',
            isMobile: true
        },
        SM: {
            MAX_WIDTH: 767,
            NAME: 'SM',
            isMobile: false,
            isTablet: true,
            isSmallTablet: true
        },
        MD: {
            MAX_WIDTH: 991,
            NAME: 'MD',
            isMobile: false,
            isTablet: true
        },
        LG: {
            MAX_WIDTH: 1199,
            NAME: 'LG',
            isMobile: false
        },
        XL: {
            MAX_WIDTH: 2500,    //arbitrary
            NAME: 'XL',
            isMobile: false
        }
    },
    LINKS: {
        JUNTOO:  'https://www.juntoo.be',
        EXTERIOO:  'https://www.exterioo.be',
        LINKED_IN: 'https://www.linkedin.com/company/belivingfurniture',
        JOIN_US:'https://jobs.beliving.be/nl',
        SHARE_YOUR_PASSION:'https://jobs.beliving.be/nl/vacatures'
    }
};

export default CONSTANTS;
