import React from 'react';
import Navigation from "./sections/Navigation/Navigation";
import OurBrands from "./sections/OurBrands/OurBrands";
import Footer from "./sections/Footer/Footer";
import Hero from "./sections/Hero/Hero";
import AboutUs from "./sections/AboutUs/AboutUs";
import JoinUsBanner from "./sections/JoinUsBanner/JoinUsBanner";
import {useBreakpointInit} from "./CustomHooks/CustomHooks";

const Beliving = () => {

    useBreakpointInit();

    return <>
        <Navigation/>
        <Hero/>
        <AboutUs/>
        <OurBrands/>
        <JoinUsBanner/>
        <Footer/>
    </>
};

export default Beliving;
