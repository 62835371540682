import React from 'react';
import {useSelector} from "react-redux";
import {ID as AboutUsId} from "../../AboutUs/AboutUs";
import classnames from "classnames";
import {ID as OurBrandsId} from "../../OurBrands/OurBrands";
import {ID as JoinUsId} from "../../JoinUsBanner/JoinUsBanner";
import CONSTANTS from "../../../../CONSTANTS/CONSTANTS";

const Tabs = ({setSideMenuIsOpened, isInSideMenu}) => {

    const handleNavigation = sectionId => {
        const sectionNode = document.getElementById(sectionId);
        const navigationNode = document.querySelector('nav.blv-horizontal-menu');

        window.scrollTo({
            top: sectionNode?.offsetTop - navigationNode?.offsetHeight,
            behavior: 'smooth'
        });
        isInSideMenu && setSideMenuIsOpened(false);
    };
    const currentActiveSectionId = useSelector(state => state?.pageInfo?.currentSection?.id);

    return <ul className={'blv-navigation-tabs'}>
        <li>
            <button onClick={() => handleNavigation(AboutUsId)}
                    className={classnames('blv-navigation-tab', 'blv-btn', {'blv-is-active': currentActiveSectionId === AboutUsId})}>Ons
                bedrijf
            </button>
        </li>

        <li>
            <button onClick={() => handleNavigation(OurBrandsId)}
                    className={classnames('blv-navigation-tab', 'blv-btn', {'blv-is-active': currentActiveSectionId === OurBrandsId})}>Onze
                merken
            </button>
        </li>
        <li>
            <a target={'_blank'} rel="noopener noreferrer" href={CONSTANTS.LINKS.JOIN_US}
               className={classnames('blv-navigation-tab', 'blv-btn', {'blv-is-active': currentActiveSectionId === JoinUsId})}>Kom
                bij ons werken
            </a>
        </li>
        <li>
            <a target={'_blank'} rel={'noopener noreferrer'} href={CONSTANTS.LINKS.LINKED_IN}
               className={classnames('blv-navigation-tab', 'blv-btn', 'blv-linked-in-nav-tab')}>{!isInSideMenu &&
            <i className="fab fa-linkedin icon blv-linked-in-logo"></i>}
            </a>
        </li>
    </ul>
};
export default Tabs;
