import React from 'react';
import classnames from "classnames";
import {useAddSectionToScrollspy} from "../../CustomHooks/CustomHooks";
import reponsiveBannerImages from "./assets/index";
import Picture from "../../partials/Picture";
import CONSTANTS from "../../../CONSTANTS/CONSTANTS";

const JoinUs = () => {

   useAddSectionToScrollspy(ID);

    return <section id={ID} className={classnames('blv-join-us-section', 'blv-page-section')}>
        <Picture {...{
            XS: reponsiveBannerImages.joinUsBannerXs,
            SM: reponsiveBannerImages.joinUsBannerSm,
            MD: reponsiveBannerImages.joinUsBannerMd,
            LG: reponsiveBannerImages.joinUsBannerLg,
            XL: reponsiveBannerImages.joinUsBannerXl,
        }}/>
        <div className={'blv-join-us-text-wrapper'}>
            <div className={'blv-join-us-text'}>
                <h2>Deel je passie</h2>
                <p>Niet alleen onze merken, maar ook wij groeien. We kunnen dus mensen met passie voor
                    interieur en buitenleven goed gebruiken. Heb jij het in je om mensen te inspireren om volop
                    te genieten van thuis, binnen én buiten?</p>
                <a target={'_blank'} rel={"noopener noreferrer"} className={classnames('blv-join-us-cta', 'bl-cta')} href={CONSTANTS.LINKS.JOIN_US}>
                    <strong>Ontdek onze vacatures <i className="fas fa-arrow-right"></i></strong>
                </a>
            </div>
        </div>
    </section>;
};

export default JoinUs;
export const ID = 'join-us';


