import React from 'react';
import classnames from 'classnames';
import {useAddSectionToScrollspy} from '../../CustomHooks/CustomHooks';
import Picture from "../../partials/Picture";
import reponsiveBannerImages from './assets/index';

const Hero = () => {

    useAddSectionToScrollspy(ID);

    return <section id={ID} className={classnames('blv-hero-section', 'blv-page-section')}>
        <Picture {...{
            XS: reponsiveBannerImages.heroBannerXs,
            SM: reponsiveBannerImages.heroBannerSm,
            MD: reponsiveBannerImages.heroBannerMd,
            LG: reponsiveBannerImages.heroBannerLg,
            XL: reponsiveBannerImages.heroBannerXl,
        }}/>
        <div className={'blv-hero-text'}>
            <h1>Better living at home</h1>
        </div>
    </section>;
};

export default Hero;
export const ID = 'main-hero';
